import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { useCallback } from 'react'

export type UseChatRequestsResult = {
    getUserGoals: () => Promise<Response>
    getWelcomeMessage: () => Promise<Response>
    getChatHistory: (body: any) => Promise<Response>
}

function useChatRequests(): UseChatRequestsResult {
    const base_api = process.env.AI_SERVICE_ENV
    const auth = useAppSelector((state) => state.auth)

    const getUserGoals = useCallback(async () => {
        return fetch(`${base_api}/api/v1/goals/user_goals`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.accessToken}`
            }
        })
    }, [auth.accessToken, base_api])

    const getWelcomeMessage = useCallback(async () => {
        return fetch(`${base_api}/api/v1/chat/fixed-messages`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.accessToken}`
            }
        })
    }, [auth.accessToken, base_api])

    const getChatHistory = useCallback(
        async (body) => {
            return fetch(`${base_api}/api/v1/chat/agent`, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.accessToken}`
                }
            })
        },
        [auth.accessToken, base_api]
    )

    return { getUserGoals, getWelcomeMessage, getChatHistory }
}

export default useChatRequests
